jQuery(document).ready(function ($) {
  const BOWE_WOO_COUPONS = {
    init: function () {
      $(document).on("click", ".apply-coupon", this.applyCoupon);
      $("body").on("click", ".bowe-minicart-applied-discounts-item-remove", this.removeCoupon);
    },

    applyCoupon: function (e) {
      e.preventDefault(); // Prevent the form from refreshing the page

      let couponCode = $("#coupon_code").val();

      BOWE_WOO_COUPONS.ajax(
        {
          action: "apply_coupon_via_ajax",
          coupon_code: couponCode,
        },
        function (response) {
          // Show success or error message based on response
          if (response.success) {
            BOWE_WOO_COUPONS.showNotice(
              response.data?.message || "Coupon applied successfully!",
              "success"
            );

            setTimeout(() => {
              updateFragment();
            }, 500);
          } else {
            BOWE_WOO_COUPONS.showNotice(response.data?.message || "Failed to add coupon.", "error");
          }
        }
      );
    },

    removeCoupon: function (e) {
      e.preventDefault(); // Prevent the link from following the href

      let couponCode = $(this).data("coupon");

      BOWE_WOO_COUPONS.ajax(
        {
          action: "remove_coupon_via_ajax",
          coupon_code: couponCode,
        },
        function (response) {
          //updateFragment();

          // Show success or error message based on response
          if (response.success) {
            updateFragment();
          } else {
            BOWE_WOO_COUPONS.showNotice(
              response.data?.message || "Failed to remove coupon.",
              "error"
            );
          }
        }
      );
    },

    showNotice: function (message, type = "success") {
      const noticeWrapper = $("#cart .woocommerce-notices-wrapper");

      // Create a new notice element
      const notice = $(`<div class="woocommerce-message ${type}">${message}</div>`);

      // Append it to the wrapper
      noticeWrapper.append(notice);

      // Automatically hide the notice after 5 seconds
      setTimeout(() => {
        notice.fadeOut(2000, function () {
          $(this).remove(); // Remove the element entirely after fade-out
        });
      }, 5000);
    },

    ajax: function (data, successCallback) {
      return $.ajax({
        url: wc_add_to_cart_params.ajax_url, // The URL to send the request to
        type: "POST", // HTTP method (GET, POST, etc.)
        data: data,
        success:
          successCallback ||
          function (response) {
            // Default behavior if no successCallback is provided
            console.log("Response:", response);
          },
        error: function (xhr, status, error) {
          // Code to handle an error response
          console.error("Error:", error);
        },
      });
    },
  };

  BOWE_WOO_COUPONS.init();

  function updateFragment() {
    // Check if body contains the 'woocommerce-checkout' class
    if ($("body").hasClass("woocommerce-checkout")) {
      // If on checkout page, trigger the WooCommerce checkout update
      jQuery("body").trigger("update_checkout");
    } else {
      // If not on checkout page, refresh WooCommerce cart fragments
      $(document.body).trigger("wc_fragment_refresh");
    }
  }
});
