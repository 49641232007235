jQuery(document).ready(function ($) {
  // Function to fetch upsell products via AJAX and update the minicart
  $(document).on("click", "#minicart-upsell-products .quantity__increment.plus", function () {
    upsell_quantity_updates($(this), "plus");
  });

  $(document).on("click", "#minicart-upsell-products .quantity__increment.minus", function () {
    upsell_quantity_updates($(this), "minus");
  });

  $(document).on("input", "#minicart-upsell-products .quantity__input", function () {
    upsell_quantity_updates($(this), "input");
  });

  function upsell_quantity_updates(elmnt, type) {
    const quantity_input = elmnt.parents(".quantity").find(".quantity__input");
    const add_to_cart_btn = elmnt.parents(".minicart-upsell-item").find(".add_to_cart_button");

    let quantity = parseInt(quantity_input.val());

    switch (type) {
      case "minus":
        quantity -= 1;
        break;
      case "plus":
        quantity += 1;
        break;
        deafult: break;
    }

    if (quantity > 0) {
      quantity_input.val(quantity);
      add_to_cart_btn.attr("data-quantity", quantity);
    }
  }
});
