jQuery(document).ready(function ($) {
  // Timer variable for the updateQuantity function
  let updateTimer;

  // Function to update the quantity
  function updateQuantity(input, increment) {
    var currentVal = parseInt(input.val(), 10);
    var minVal = parseInt(input.attr("min"), 10) || 1; // Default to 1 if no min is set
    var newVal = increment ? currentVal + 1 : Math.max(currentVal - 1, minVal); // Ensures value doesn't go below min

    input.val(newVal);

    // Clear any existing timer
    clearTimeout(updateTimer);

    // Set a new timer to trigger after 1 second
    updateTimer = setTimeout(function () {
      input.trigger("change");
    }, 500);
  }

  // Event delegation for dynamically added elements or multiple items

  $(document).on("click", ".bowe-minicart-items-item .plus", function () {
    var input = $(this).closest(".quantity").find(".input-text.qty.text"); // Adjust based on actual structure
    updateQuantity(input, true);
  });

  $(document).on("click", ".bowe-minicart-items-item .minus", function () {
    var input = $(this).closest(".quantity").find(".input-text.qty.text"); // Adjust based on actual structure
    updateQuantity(input, false);
  });

  // Delay update after typing
  var typingTimer;

  $(document).on("input", ".bowe-minicart-items-item .input-text.qty.text", function () {
    clearTimeout(typingTimer);
    var input = $(this);
    typingTimer = setTimeout(function () {
      input.trigger("change");
    }, 2000); // 2 seconds delay
  });

  // Listen for quantity change to update cart

  $(document).on("change", " .bowe-minicart-items-item .input-text.qty.text", function () {
    console.log("Why minicart/modules/quantity.js is this runing, when 0 in the cart...???");

    var itemKey = $(this).closest(".woocommerce-mini-cart-item").data("cart_item_key");
    var newQuantity = $(this).val();

    $.ajax({
      url: wc_cart_fragments_params.ajax_url,
      type: "POST",
      data: {
        action: "update_cart_quantity",
        cart_item_key: itemKey,
        quantity: newQuantity,
        security: wc_cart_fragments_params.ajax_nonce, // Assuming nonce verification
      },
      success: function (response) {
        $(document.body).trigger("wc_fragment_refresh");
      },
      error: function (error) {
        console.error("Cart update failed:", error);
      },
    });
  });
});
